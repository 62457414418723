/* .hide{
  display: none;
} */

/* .MuiInput-root{
  width: 100% !important;
} */

.confirmationCode {
  display: flex;
  align-self: center;
  justify-content: center;
}

.confirmationCode > div:nth-child(2) h3 {
  font-size: 25px;
  margin: 0px;
}
.fa-download {
  cursor: pointer;
  color: red;
  font-size: 25px;
  margin-left: 25px;
}
.confirmationCode > div:nth-child(1) h3 {
  color: #f07167;
  font-weight: unset;
  font-size: 25px;
  font-weight: 300;
  margin: 0px;
  margin-right: 0.5em;
}

.lefImage h1 {
  color: #f07167;
  font-weight: bold;
  margin: 0.2em;
}
.paperModal {
  position: absolute;
  width: 60%;
  background-color: #fff;
  border-radius: 20px;
}
.buttonNext {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
}

.secCompany {
  color: #00afb9;
  margin: 0em;
}

.buttonNext button {
  background: #f07167;
  border-radius: 16px;
  border-radius: 8px;
  padding: 0.5em 1em;
  color: #fff;
  border: none;
  font-size: 16px;
  cursor: pointer;
  float: left;
}

.loaderAdj {
  display: flex;
  justify-content: center;
  align-items: center;
}

.submit .btn {
  background: #f07167;
  border-radius: 16px;
  border-radius: 8px;
  padding: 0.8em 6em;
  color: #fff;
  border: none;
  font-size: 16px;
  cursor: pointer;
  /* height: 47px; */
}

.submit {
  margin-bottom: 3em;
  display: flex;
  justify-content: center;
}

.captcha {
  margin: 2em 0;
  display: flex;
  justify-content: center;
}

.accordionHolder {
  width: 60%;
  margin: 0 auto;
}

.accordionbg {
  position: relative;
}

.accSummary {
  background: linear-gradient(90deg, #6fe2e9 2.97%, #00afb9 100%);
  border-radius: 10px;
  padding-top: 7px;
  width: 100%;
}

.content {
  background: linear-gradient(90deg, #6fe2e9 2.97%, #00afb9 100%);
  padding: 1em 0;
  border-radius: 20px;
  clip-path: polygon(0% 0%, 88% 0%, 100% 28%, 100% 100%, 0% 100%);
  height: 210px;
}

.center {
  display: flex;
  justify-content: center;
}
.center img {
  height: 250px;
}

.rander {
  position: relative;
}

.rander img {
  position: absolute;
  height: 100px;
  height: 168px;
  left: 50%;
  transform: translate(-160%, 50%);
}

.successmsg {
  text-align: center;
  color: #00afb9;
}

.successmsg h1 {
  font-weight: 400;
}

.closeHolder {
  position: relative;
  cursor: pointer;
}

.closeHolder img {
  position: absolute;
  top: 15px;
  right: 10px;
  z-index: 11;
  height: 25px;
}

.copyRight {
  color: #00afb9;
}

.adjs {
  font-size: 12px;
}

@media only screen and (max-width: 600px) {
  .lefImage h1 {
    font-size: 16px;
  }

  .paperModal {
    position: absolute;
    width: 86%;
    background-color: #fff;
    border-radius: 20px;
  }
  .center img {
    height: 191px;
  }
  .closeHolder img {
    position: absolute;
    top: 9px;
    right: 6px;
    z-index: 11;
    height: 23px;
  }

  .successmsg h1 {
    font-weight: 400;
    font-size: 20px;
  }
  .content {
    background: linear-gradient(90deg, #6fe2e9 2.97%, #00afb9 100%);
    padding: 1em 0;
    border-radius: 20px;
    -webkit-clip-path: polygon(0% 0%, 88% 0%, 100% 28%, 100% 100%, 0% 100%);
    clip-path: polygon(0% 0%, 83% 0%, 100% 28%, 100% 100%, 0% 100%);
  }

  .rander img {
    position: absolute;
    height: 100px;
    height: 99px;
    left: 50%;
    transform: translate(-160%, 50%);
  }

  .adjs {
    font-size: 12px;
  }

  .accordionHolder {
    width: 100%;
  }

  .bannerRight {
    position: absolute;
    right: -32px;
    height: 78px;
    top: 54px;
  }

  .bannerHolder h1 {
    margin-top: 0.5em;
    font-size: 1.4em;
  }

  .copyRight {
    text-align: center;
  }
}
.popImage {
  max-height: 120px;
}
.popImage1 {
  max-height: 180px;
}
@media only screen and (min-width: 601px) and (max-width: 768px) {
  .accordionHolder {
    width: 100%;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .adjs {
    font-size: 12px;
  }

  .adjsd {
    font-size: 12px;
  }
}

/* ====pop up Modal css */

.react-confirm-alert-body > h1 {
	font-size: 20px !important;
}
.react-confirm-alert-overlay{
	background: rgba(255, 255, 255, 0.6) !important;
}
.react-confirm-alert-body{
	box-shadow: 0 6px 17px rgb(0 0 0 / 25%) !important;
  width: 650px !important;
}

.react-confirm-alert-button-group > button:nth-child(1){

	background-color: #4bd3c5 !important;
	font-weight: bold;
	color:#fff;
}
.react-confirm-alert-button-group > button:nth-child(2){

	background-color:#f07167 !important;
	font-weight: bold;
	color:#fff;
}
.Status_name{
	color:  #4bd3c5 !important;
  margin-left: 7px;
}

.modalPhoneNumber > .react-tel-input .form-control{
  border: 0px solid #CACACA !important;
  color:  #4bd3c5 !important;
  padding-left: 37px !important;
  font-size: 15px !important; 
}

.modalPhoneNumber > .react-tel-input .flag-dropdown{
  background-color: #fff !important;
  border: 0px solid #CACACA !important;
}
@media only screen and (max-width: 768px) {
  .react-confirm-alert-body{   
    width: 320px !important;
  }
}

@media only screen and (max-width: 340px) {
  .react-confirm-alert-body{   
    width: 280px !important;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .react-confirm-alert-body{   
    width: 450px !important;
  }
}